import React, {FC, memo} from 'react';
import './styles.scss';
interface ISquare {
  size: 'small' | 'large' | 'medium';
  className?: string;
}

const Square: FC<ISquare> = ({size, className}) => (
  <div className={`square ${size} ${className ? className : ''}`}></div>
);

export default memo(Square);
