import {navigate} from 'gatsby';
import React from 'react';
import gif from '../../../images/Hexomatic_Translation.gif';
import {rightArrow} from '../../../images/svgIcons';
import Button from '../../button';
import Square from '../../square';

import './styles.scss';

const credits = [
  'ChatGPT',
  'DeepL & Google Translate',
  'Google & Google Maps data',
  'Email enrichment',
  'Residential proxies',
  'Amazon Data',
  'SEO backlink data',
];
const PremiumCredits = () => (
  <div className="premium-credits-section">
    <div className="row pricing-premium-credits my-0">
      <div className="col-12 order-1">
        <h2 className="f_size_22 f_700 l_height40 mb_15 text-center text-lg-left">Premium credit add-on</h2>
      </div>
      <div className="col-12 col-lg-6 order-3 order-lg-2">
        <div>
          Tap into premium machine translation, find outbound leads, enrich leads with contact information and access residential proxies. This add-on includes access to:
        </div>
        <div className="my-3 row">
          {credits.map((item: string) => (
            <div className="credits col-12 col-sm-6" key={item}>
              <span className="pr-1">{rightArrow}</span> {item}
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between bg-white learn-more-section">
          <div className="d-flex align-items-center starting-from">
            <div className="starting">Starting from just</div>
            <div className="price">
              <sup>$</sup>9<sup>99</sup>
              <sub>/mo</sub>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Button onClick={() => navigate('/premium-credits/')} className="learn-more">
              Learn more
            </Button>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 text-center position-relative order-2 order-lg-3 px-0 pb-4 pb-lg-0">
        <Square size="small" className="small_square" />
        <Square size="medium" className="medium_square" />
        <img src={gif} alt="credits" className="gif-img" />
      </div>
    </div>
  </div>
);

export default PremiumCredits;
